import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import EinladungIndex from "../views/EinladungIndex.vue";
import AccountView from "../views/AccountView.vue";
import PasswordView from "../views/PasswordView.vue";
import BestellungView from "../views/BestellungView.vue";
import DirectLogin from "../views/DirectLogin.vue";
import DirectBestellung from "../views/DirectBestellung.vue";
import DirectLoginweiter from "../views/DirectLoginweiter.vue";
import InfoView from "../views/InfoView.vue";
import VeranstaltungIndex from "../views/VeranstaltungIndex.vue";
import DigitalAusgabe from "../views/DigitalAusgabe.vue";
import BudgetplanView from "../views/BudgetplanView.vue";
import FacebookLogin from "../views/FacebookLogin.vue";
import DownloadWaizmanntabelle from "../views/DownloadWaizmanntabelle.vue";
import ProphylaxeView from "../views/wissen/ProphylaxeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/plan",
    name: "plan",
    component: BudgetplanView,
  },
  {
    path: "/facebooklogin",
    name: "facebooklogin",
    component: FacebookLogin,
  },
  {
    path: "/prophylaxe",
    name: "ProphylaxeView",
    component: ProphylaxeView,
  },
  {
    path: "/login/:thema?",
    name: "login",
    component: LoginView,
  },

  {
    path: "/info/:site",
    name: "info",
    component: InfoView,
  },
  {
    path: "/webinare/:info?",
    name: "webinareindex",
    component: VeranstaltungIndex,
  },
  {
    path: "/einladung/:nr",
    name: "webinareinladung",
    component: EinladungIndex,
  },
  {
    path: "/start/:token",
    name: "start",
    component: DirectLogin,
  },
  {
    path: "/aufsteller/:token",
    name: "auftsteller",
    component: DirectBestellung,
  },
  {
    path: "/startwebinar/:token",
    name: "startweiter",
    component: DirectLoginweiter,
  },
  {
    path: "/anmelden_kostenlos",
    name: "anmelden_kostenlos",
    component: RegisterView,
  },

  {
    path: "/waizmanntabellen_bestellen",
    name: "waizmanntabellen_bestellen",
    component: BestellungView,
    meta: { requiresAuth: true },
  },
  {
    path: "/digitalausgabe",
    name: "digitalausgabe",
    component: DigitalAusgabe,
  },
  {
    path: "/download_waizmanntabelle",
    name: "download_waizmanntabelle",
    component: DownloadWaizmanntabelle,
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: { requiresAuth: true },
  },
  {
    path: "/password_change",
    name: "password",
    component: PasswordView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  import("@/stores/user").then(({ useUserStore }) => {
    const userStore = useUserStore();
    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      !userStore.isLogged
    ) {
      next({ path: "/login" });
    } else if (userStore.isLogged && to.path === "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  });
});
export default router;
