<template>
  <v-container class="mt-4">
    <span v-if="successBestellungPzr">
      <h3 class="text-green">Wir werden Ihnen auch die WaizmannTabelle PZR-Flatrate kostenlos zusenden.</h3>
      </span>
    <span v-if="!successBestellungPzr">
    <span v-if="successBestellung">
    <h3 class="text-green">Wir werden Ihnen den neuen Premium-Aufsteller in Kürze kostenlos zusenden.</h3>
  </span>
  <span v-else>
    <h3 class="text-orange">Wir bearbeiten gerade Ihre Anforderung</h3>
  </span>
</span>
  <v-row class="my-4"  v-if="successBestellung && !successBestellungPzr">
     
      <v-col  cols="8">
        <h2>
         Sollen wir Ihnen auch die neue<br> WaizmannTabelle PZR-Flatrate kostenlos zusenden?
        </h2>
        T0P 10 der PZR-Flatrate Tarife. Diese Tarife leisten sowohl für
        Zahnersatz & Zahnerhalt als auch in unbegrenzter Höhe und Anzahl für
        die professionelle Zahnreinigung. Auch für mehr als 2 PZR-Behandlungen
        pro Jahr.<br>
        <v-btn color="primary" class="mt-4" @click="sendPzr()">Ja, bitte senden Sie mir die WaizmannTabelle PZR-Flatrate kostenlos zu</v-btn>
      </v-col>
      <v-col cols="4" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/bestellung/auftstellerPremiumPzr.png')"
          style="width: 280px; float: left"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { reactive, ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useUserStore } from "@/stores/user";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useRoute } from "vue-router";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  const route = useRoute();

  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();
  const successBestellung = ref(false);
  const successBestellungPzr = ref(false);
  const userLogin = reactive({
    token: route.params.token,
  });

  const bestellung = reactive({
    bestell_status: 0,
    bestell_comment: "",
    bestell_produkt_text: "onlinePremiumAufsteller",
    bestell_kategorie: 0,
  });

  const submitForm = async () => {
    try {
      console.log("sumitform");
      const resp = await BaseCalls.directLogin(userLogin);
      if (resp.data && resp.data.user) {
        console.log(resp.data.user);
        insertBestellung();
        userStore.setUser(resp.data.user);
        userStore.isLogged = true;
        userStore.showLogin = false;
     
       
      } else {
        errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
      }
    } catch (err) {
      errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
    }
  };
  if (userLogin.token != "") {
    submitForm();
  }
  async function sendPzr() {
    bestellung.bestell_produkt_text = "onlinePremiumAufstellerPzr";
    successBestellungPzr.value = true;
    insertBestellung();
  }
  async function insertBestellung() {
    console.log("insertBestellung");
    bestellung.user_action = "insertBestellung"; // Korrigiert, um .value zu verwenden
    try {
      const resp = await BaseCalls.postBestellungLogged(bestellung); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        console.log("Bestellung erfolgreich");
        successBestellung.value = true;   if (successBestellungPzr.value){
        successStore.setSuccess(
          true,
          "Ihre Anforderung wird bearbeitet",
          "Wir senden Ihnen in Kürze Ihr Waizmann-Paket kostenlos zu."
        );
        }
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Ihre Bestellung konnten nicht angelegt werden"
      );
    }
  }
</script>
